exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/blog/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-devops-index-jsx": () => import("./../../../src/pages/devops/index.jsx" /* webpackChunkName: "component---src-pages-devops-index-jsx" */),
  "component---src-pages-devops-kubernetes-jsx": () => import("./../../../src/pages/devops/kubernetes.jsx" /* webpackChunkName: "component---src-pages-devops-kubernetes-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-software-index-jsx": () => import("./../../../src/pages/software/index.jsx" /* webpackChunkName: "component---src-pages-software-index-jsx" */)
}

